import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAuth, signInWithPopup, GithubAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAeKWeRqIHt3N_OLbFwRl3T6I281Tif9Q4",
  authDomain: "sokimon-auth.firebaseapp.com",
  projectId: "sokimon-auth",
  storageBucket: "sokimon-auth.appspot.com",
  messagingSenderId: "468391083537",
  appId: "1:468391083537:web:ee80fac7c7eab788687bb0",
  measurementId: "G-8573FNLPLB",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const gitHubAuthProvider = new GithubAuthProvider();
gitHubAuthProvider.setCustomParameters({
  allow_signup: "false",
});
let gitHubAuth = getAuth();
let user;
export const signInWithGithub = () =>
  signInWithPopup(gitHubAuth, gitHubAuthProvider).then((result) => {
    // This gives you a GitHub Access Token. You can use it to access the GitHub API.
    const credential = GithubAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;

    // The signed-in user info.
    user = result.user;
    // ...
  });

export { user };

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
