import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/Pet.css";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const DEV_URL = "http://localhost:8888/all_pets/index";
const PRODUCTION_URL = "https://some_link.com/api";

export default function WildPet() {
  const [wildPetData, setWildPetData] = useState([]);

  useEffect(() => {
    axios.get(DEV_URL).then((res) => {
      setWildPetData(res);
    });
  }, []);

  const wildPet = wildPetData || [];
  //   console.log(wildPet.data[0]);
  return (
    <>
      <button
        className="abs"
        style={{ left: getRandomInt(80) + "%", top: getRandomInt(80) + "%" }}
      >
        {/* <img className="wild-pet" src={wildPet.data[0].picture} /> */}
        <img
          className="wild-pet"
          src="https://i.ytimg.com/vi/f02mOEt11OQ/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCknMkXb_Fd6--_YBFawemgvG6iCw"
        />
      </button>
    </>
  );
}
