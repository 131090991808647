import React, { useState } from "react";
import { IsSignedIn } from "./IsSignedIn";
import { IsSignedInMobile } from "./IsSignedInMobile";
import { GiAlienBug } from "react-icons/gi";
import { BiMenuAltLeft } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
export default function Navbar() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <nav className="navbar">
        <div className="flex">
          <GiAlienBug />
          <h3>Sokimón</h3>
        </div>
        <ul className="navbar-nav">
          <IsSignedIn />
        </ul>
        <div className="ham-menu">
          <button onClick={() => setOpen(true)}>
            <BiMenuAltLeft />
          </button>
        </div>
      </nav>
      <div style={{ display: isOpen ? "block" : "none" }} className="side-menu">
        <button
          onClick={() => setOpen(false)}
          style={{ color: "white", fontSize: "2em" }}
        >
          <AiFillCloseCircle />
        </button>
        <h1>Sokimón.</h1>
        <IsSignedInMobile />
      </div>
    </>
  );
}
