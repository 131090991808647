import React, { useState } from "react";
import useKeypress from "react-use-keypress";
import { changePage } from "./Game";
import "../css/App.css";
// import menu_icon from "../../images/menu_icon.svg";
import WildPet from "./WildPet";
import {
  BsCaretLeftSquareFill,
  BsCaretLeftSquare,
  BsCaretRightSquareFill,
  BsCaretRightSquare,
  BsCaretUpSquareFill,
  BsCaretUpSquare,
  BsCaretDownSquareFill,
  BsCaretDownSquare,
} from "react-icons/bs";

import { BiMenuAltLeft } from "react-icons/bi";
import Menu from "./Menu";

function LeftArrow({ left }) {
  if (left) {
    return (
      <button>
        <BsCaretLeftSquareFill />
      </button>
    );
  }
  if (!left) {
    return (
      <button disabled>
        <BsCaretLeftSquare style={{ color: "rgba(255, 255, 255, 0.384)" }} />
      </button>
    );
  }
}

function RightArrow({ right }) {
  if (right) {
    return (
      <button>
        <BsCaretRightSquareFill />
      </button>
    );
  }
  if (!right) {
    return (
      <button disabled>
        <BsCaretRightSquare style={{ color: "rgba(255, 255, 255, 0.384)" }} />
      </button>
    );
  }
}

function UpArrow({ up }) {
  if (up) {
    return (
      <button>
        <BsCaretUpSquareFill />
      </button>
    );
  }
  if (!up) {
    return (
      <button disabled>
        <BsCaretUpSquare style={{ color: "rgba(255, 255, 255, 0.384)" }} />
      </button>
    );
  }
}

function DownArrow({ down }) {
  if (down) {
    return (
      <button>
        <BsCaretDownSquareFill />
      </button>
    );
  }
  if (!down) {
    return (
      <button disabled>
        <BsCaretDownSquare style={{ color: "rgba(255, 255, 255, 0.384)" }} />
      </button>
    );
  }
}

function Arrows({ left, right, up, down }) {
  return (
    <>
      <UpArrow up={up} />
      <DownArrow down={down} />
      <LeftArrow left={left} />
      <RightArrow right={right} />
    </>
  );
}

export default function Page({
  bgImage,
  left,
  right,
  up,
  down,
  user,
  setPage,
}) {
  const [isOpen, setOpen] = useState(false);

  useKeypress("ArrowUp", () => {
    if (up) {
      changePage(up);
      changePage(up, setPage());
      alert("up");
    }
  });

  useKeypress("ArrowDown", () => {
    if (down) {
      changePage(down);
      alert("down");
      changePage(down, setPage());
    }
  });

  useKeypress("ArrowRight", () => {
    if (right) {
      changePage(right);
      alert("right");
      changePage(right, setPage());
    }
  });

  useKeypress("ArrowLeft", () => {
    if (left) {
      changePage(left);
      alert("left");
      changePage(left, setPage());
    }
  });

  return (
    <>
      <div
        className="fullScreen"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      >
        <div className="menu">
          <button onClick={() => setOpen(true)}>
            <BiMenuAltLeft />
          </button>
        </div>
        <div
          className="main-menu"
          style={{ display: isOpen ? "block" : "none" }}
        >
          <Menu user={user} close={() => setOpen(false)} />
        </div>
        <WildPet />
        <WildPet />
        <WildPet />
        <div style={{ display: isOpen ? "none" : "block" }}>
          <div className="pageContols">
            <Arrows left={left} right={right} up={up} down={down} />
          </div>
        </div>
      </div>
    </>
  );
}
