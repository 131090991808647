import { signInWithGithub, signInWithGoogle } from "./firebase";

import { FcGoogle } from "react-icons/fc";
import "../css/App.css";
import { BsGithub } from "react-icons/bs";

const Login = () => {
  return (
    <div className="centered">
      <div className="user">
        <br />
        <h1>Welcome to Sokimón!</h1>
        <br />
        <button className="google_button" onClick={signInWithGoogle}>
          <FcGoogle />
          <span> Continue with Google</span>
        </button>
        <br />
        <button className="github_button" onClick={signInWithGithub}>
          <BsGithub style={{ color: "white" }} />
          <span> Continue with Github</span>
        </button>
      </div>
    </div>
  );
};

export default Login;
