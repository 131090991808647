import React, { useState, useEffect } from "react";
// import Game from "../Components/Game";
import Login from "../../auth/Login";
import firebase from "../../auth/firebase";
import Game from "../Components/Game";

export default function GameApp() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  console.log(user);

  return <>{user ? <Game user={user} /> : <Login />}</>;
}
