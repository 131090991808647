import React, { useState } from "react";
import Page from "./Page";
import "../../css/App.css";

if (!localStorage.getItem("page")) {
  localStorage.setItem("page", "home");
}

export function changePage(pageID, setPage) {
  localStorage.setItem("page", pageID);
  setPage(localStorage.getItem("page"));
}

export default function Game({ user }) {
  const [page, setPage] = useState(localStorage.getItem("page"));
  switch (page) {
    case "home":
      return (
        <Page
          up="home-2"
          user={user}
          bgImage="https://djmag.com/sites/default/files/styles/djmag_landscape__691x372_/public/2022-05/Screenshot%202022-05-09%20at%2013.16.47.png?itok=8F96x33k"
          setPage={setPage}
        />
      );
    case "home-2":
      return (
        <Page
          down="home"
          user={user}
          up="forest-left"
          bgImage="https://cdn.mos.cms.futurecdn.net/RpZ6pK8oFmJmHrkz266znZ.jpg"
          setPage={setPage}
        />
      );
    case "forest-left":
      return (
        <Page
          down="home-2"
          right="forest-right"
          user={user}
          bgImage="https://www.nasa.gov/sites/default/files/cygx1_ill.jpg"
          setPage={setPage}
        />
      );
    case "forest-right":
      return (
        <Page
          down="home"
          left="forest-left"
          user={user}
          bgImage="https://www.wgtn.ac.nz/__data/assets/image/0012/288939/varieties/ls_medium.jpg"
          setPage={setPage}
        />
      );
  }
}

// https://wp.technologyreview.com/wp-content/uploads/2021/07/PIA23864_fig1.jpg?w=3000

// https://www.pbs.org/wgbh/nova/media/original_images/universe-black-holes-img.jpg
