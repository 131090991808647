import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import firebase, { auth } from "./assets/auth/firebase";

export function IsSignedIn() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  console.log(user);
  if (user) {
    return (
      <>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/o/play">Play</Link>
        </li>

        <li>
          <button className="button-thin" onClick={() => auth.signOut()}>
            <span> Log Out</span>
          </button>
        </li>
      </>
    );
  } else {
    return (
      <>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/o/login">Login</Link>
        </li>
      </>
    );
  }
}
