import "../css/App.css";
import React from "react";
import ToastMaker from "toastmaker";
import "toastmaker/dist/toastmaker.css";

import { auth } from "./firebase";

const UserEmail = ({ user }) => {
  if (user.email) {
    return (
      <p>
        Logged in as <code>{user.email}</code>
      </p>
    );
  } else {
    return (
      <p>
        Logged in with <code>Github</code>
      </p>
    );
  }
};

const MainDashboard = ({ user }) => {
  return (
    <div className="user">
      <h1>
        Hello, <span></span>
        {user.displayName}
      </h1>
      <img src={user.photoURL} alt="profile-pic" className="profile-pic" />
      <br />

      <UserEmail user={user} />
      <br />
      <br />
      <h5>Id: {user.uid}</h5>
      <button
        className="button"
        onClick={() => {
          auth.signOut();
          ToastMaker("You have signed out successfully!", 5000, {
            classList: ["toast"],
          });
        }}
      >
        <span> Log Out</span>
      </button>
    </div>
  );
};

const YouNeedToSignIn = () => {
  return <h1>Oops. You need to sign in!</h1>;
};

const Dashboard = ({ user }) => {
  return <>{user ? <MainDashboard user={user} /> : <YouNeedToSignIn />}</>;
};

export default Dashboard;
