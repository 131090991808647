import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
// import axios from "axios";
import Game from "./assets/game/App/App";
import Login from "./assets/auth/Login";
import Dashboard from "./assets/auth/Dashboard";
import Navbar from "./Navbar";
import firebase from "./assets/auth/firebase";

const RouteSwitch = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  console.log(user);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="o/play" element={<Game />} />
        <Route path="o/user/:userId" element="" />
        <Route path="o/login" element={<Login />} />
        <Route path="o/dashboard" element={<Dashboard user={user} />} />
        <Route
          path="*"
          element={
            <div className="App">
              <header className="App-header">
                <div className="App-backdrop">
                  <Navbar />
                  <h1>Oops.</h1>
                  <h5>
                    The page you were looking for doesn't exist. Contact the
                    owner for more information.
                  </h5>
                  <code>404 Error</code>
                </div>
              </header>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteSwitch;
