import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";

function IfEmail({ user }) {
  if (user.email) {
    return <code>{user.email}</code>;
  }
}

const DEV_URL = "http://localhost:8888";
const PRODUCTION_URL = "https://some_link.com/api";

function getUserData(url, setData) {
  // axios.get(url).then((res) => {
  //   setData(res);
  // });
}

export default function Menu({ close, user }) {
  // const [usrCookie] = useCookies(["user"]);

  const [userData, setUserData] = useState(null);

  getUserData(DEV_URL, setUserData);
  return (
    <>
      <button onClick={() => close()} style={{ color: "white" }}>
        <AiFillCloseCircle />
      </button>
      <h1>Sokimón.</h1>
      <img src={user.photoURL} alt="profile-pic" className="profile-pic-dash" />

      {/* {userData.username} */}
      <IfEmail user={user} />
    </>
  );
}
