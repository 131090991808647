import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import firebase, { auth } from "./assets/auth/firebase";

export function IsSignedInMobile() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  console.log(user);
  if (user) {
    return (
      <>
        <Link to="/">Home</Link>
        <br />

        <Link to="/o/play">Play</Link>

        <br />

        <button className="button-thin" onClick={() => auth.signOut()}>
          <span> Log Out</span>
        </button>
        <br />
      </>
    );
  } else {
    return (
      <>
        <Link to="/">Home</Link>
        <br />

        <Link to="/o/login">Login</Link>
        <br />
      </>
    );
  }
}
