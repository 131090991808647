import { useState } from "react";

import { Link } from "react-router-dom";
import "./assets/css/App.css";
import { useCookies } from "react-cookie";
import Navbar from "./Navbar";
import { CgArrowDownO } from "react-icons/cg";
export let signedIn;

function App() {
  const [signedInCookie] = useCookies(["isSignedIn"]);

  if (signedInCookie == true) {
    signedIn = true;
  } else {
    signedIn = false;
  }
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-backdrop">
          <Navbar />
          <h1>Sokimón.</h1>
          <h5>The Pokémon of tomorrow.</h5>
          <Link to="/o/play">
            <button className="button-prim">Create an account today.</button>
          </Link>

          <br />
          <br />
          {/* <CgArrowDownO */}
          <CgArrowDownO />
        </div>
      </header>
      <br />
      <p>
        You are a human. You have been banished from Earth, and to Venus, by bio
        enginered monsters you call the Phoenix. They were part of a science
        mission to create more powerfull animails. They turned aganst you, and
        your father was killed in the attack. Your misson? To take back Earth.
      </p>
      <br />
      <br />
    </div>
  );
}

export default App;
